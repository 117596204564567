<template>
    <b-container fluid>
        <b-row v-show="!$route.params.page">
            <b-col lg="6" md="12" class="pt-3">
                <div class="mb-3">
                    <div class="row align-items-center">
                        <div class="col">
                            <h3 class="mb-1">Authentication</h3>
                        </div>
                    </div>
                </div>
                Before using API you have to use your credentials to get <strong>“AccessToken”</strong>
                and <strong>“RefreshToken”</strong>. We use OAuth 2 protocol of signing API.
                <strong>"AccessToken"</strong>. Next APIs calls require you to include an A
                ccessToken in the request
                header to authenticate requests.
            </b-col>
            <b-col lg="6" md="12">
                <b-card bg-variant="light" title="Request:">
                    <b-card-text>
                        <pre v-highlightjs>
<code class="http rounded">POST /api/auth/login-by-phone HTTP/1.1
Content-Type: application/json; charset=utf-8

{
    "PhoneNumber": "+46707000000",
    "Password": "mystrongpass"
}</code></pre>
                    </b-card-text>
                </b-card>
                <b-card bg-variant="light" title="Response:">
                    <b-card-text>
                        <pre
                            v-highlightjs
                        ><code class="rounded">{{authResponse | prettyJson}}</code></pre>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>
        <b-row v-show="$route.params.page == 'accesses'">
            <b-col lg="6" md="12" class="pt-3">
                <div class="mb-3">
                    <div class="row align-items-center">
                        <div class="col">
                            <h3 class="mb-1">Key accesses</h3>
                        </div>
                    </div>
                </div>
                <p>
                    Get list of users who have access to the key. Each user has defined access type and details such as calendar<br />
                </p>
                <h4>REQUEST</h4>
                <p>
                    <strong>KeyId</strong> - Created when the user adds a new
                    door.
                </p>
                <h4>RESPONSE</h4>
                <p>
                    <strong>Id</strong> - User key access Id. Generated when the user
                    creates an invite. One user in the key = 1 key access.
                </p>
                <p>
                    <strong>RoleId</strong> - The Role Id of the invited user.
                     <ul>
                        <li>1 - Company Owner</li>
                        <li>2 - Location Owner</li>
                        <li>3 - Company Manager</li>
                        <li>4 - Location Manager</li>
                        <li>5 - Installer</li>
                        <li>6 - Permanent</li>
                        <li>7 - Temporary</li>
                        <li>8 - Key Manager</li>
                    </ul>
                    
                </p>
                <p>
                    <strong>Role</strong> - The Role details and description
                    object.
                </p>
                <p>
                    <strong>User</strong> - The key owner data is displayed. Key
                    owner gets their own data, the manager gets the owner's
                    data.
                </p>
                <p>
                    <strong>Calendar</strong><br />
                    <strong>Start/End Date</strong> - The date of the start/end
                    of access to the key, in GMT format.<br />
                    <strong>DayNumber</strong> - The sequence number of the week
                    when the user has access to the key. 0 - Monday<br />
                    <strong>Start/End Time</strong> - display time access limits to the key. 
                    The time is counted in minutes. Start countdown at 00:00 a.m. 
                    For example if you want to give an access from 8:00 to 13:00 you have to pass 480 and 880
                </p>
                <p>
                    <strong>Key</strong><br />
                    <strong>DevicesCount</strong> - Count of doors in the
                    key.<br />
                    <strong>UsersCount</strong> - Count of users in the key.
                    Сounted with the roles: Company Owner, Location Owner,
                    Company Manager, Location Manager, Installer, Key manager,
                    Permanent, Temporary.<br />
                    <strong>InvitesCount</strong> - Count of shares of the
                    key.<br />
                    <strong>MayShare</strong> - Boolean which indicates sharing access to 3-rd users. 
                    It’s can only be used with Permanent access. So if you give a Permanent access 
                    with MayShare = true option this user will be able to share key to 3-rd users. 
                    But keep in mind that giving permission with MayShare is allowed for owners and managers only. 
                    Permanent users are not allowed to do sharing with MayShare.
                </p>
            </b-col>
            <b-col lg="6" md="12">
                <b-card bg-variant="light" title="Request:">
                    <b-card-text>
                        <pre v-highlightjs>
<code class="http rounded">GET /api/key/{keyId}/accesses HTTP/1.1
Content-Type: application/json; charset=utf-8
Authorization: Bearer AccessToken
</code></pre>
                    </b-card-text>
                </b-card>
                <b-card bg-variant="light" title="Response:">
                    <b-card-text>
                        <pre
                            v-highlightjs
                        ><code class=" rounded" >{{keyUsersResponse | prettyJson}}</code></pre>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>
        <b-row v-show="$route.params.page == 'keys'">
            <b-col lg="6" md="12" class="pt-3">
                <div class="mb-3">
                    <div class="row align-items-center">
                        <div class="col">
                            <h3 class="mb-1">User keys</h3>
                        </div>
                    </div>
                </div>
                Get list of keys which current user have access to. For each
                user defined access type, and access details such as access by
                days
                <h4>RESPONSE</h4>
                <p>
                    <strong>KeyId</strong> - Key Id.
                </p>
                <p>
                    <strong>RoleId</strong> - The Role Id of the invited user.
                     <ul>
                        <li>1 - Company Owner</li>
                        <li>2 - Location Owner</li>
                        <li>3 - Company Manager</li>
                        <li>4 - Location Manager</li>
                        <li>5 - Installer</li>
                        <li>6 - Permanent</li>
                        <li>7 - Temporary</li>
                        <li>8 - Key Manager</li>
                    </ul>
                    
                </p>
                <p>
                    <strong>Role</strong> - The Role details and description
                    object.
                </p>
                <p>
                    <strong>User</strong> - The key owner data is displayed. Key
                    owner gets their own data, the manager gets the owner's
                    data.
                </p>
                <p>
                    <strong>Calendar</strong><br />
                    The calendar access to key for users. For Company Owner, Location Owner,
                    Company Manager, Location Manager, Installer, Key manager  null  Permanent, Temporary should be object. <br>
                    <strong>Start/End Date</strong> - The date of the start/end
                    of access to the key, in GMT format.<br />
                    <strong>DayNumber</strong> - The sequence number of the week
                    when the user has access to the key. 0 - Monday<br />
                    <strong>Start/End Time</strong> - display time access limits to the key. 
                    The time is counted in minutes. Start countdown at 00:00 a.m. 
                    For example if you want to give an access from 8:00 to 13:00 you have to pass 480 and 880
                </p>
                <p>
                    <strong>Key</strong><br />
                    <strong>DevicesCount</strong> - Count of doors in the
                    key.<br />
                    <strong>UsersCount</strong> - Count of users in the key.
                    Сounted with the roles: Company Owner, Location Owner,
                    Company Manager, Location Manager, Installer, Key manager,
                    Permanent, Temporary.<br />
                    <strong>InvitesCount</strong> - Count of shares of the
                    key.<br />
                    <strong>MayShare</strong> - Boolean which indicates sharing access to 3-rd users. 
                    It’s can only be used with Permanent access. So if you give a Permanent access 
                    with MayShare = true option this user will be able to share key to 3-rd users. 
                    But keep in mind that giving permission with MayShare is allowed for owners and managers only. 
                    Permanent users are not allowed to do sharing with MayShare.
                </p>
            </b-col>
            <b-col lg="6" md="12">
                <b-card bg-variant="light" title="Request:">
                    <b-card-text>
                        <pre v-highlightjs>
<code class="http rounded">GET /api/user/key HTTP/1.1
Content-Type: application/json; charset=utf-8
Authorization: Bearer AccessToken
</code></pre>
                    </b-card-text>
                </b-card>
                <b-card bg-variant="light" title="Response:">
                    <b-card-text>
                        <pre
                            v-highlightjs
                        ><code class=" rounded">{{userKeysResponse | prettyJson}}</code></pre>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>
        <b-row v-show="$route.params.page == 'share'">
            <b-col lg="6" md="12" class="pt-3">
                <div class="mb-3">
                    <div class="row align-items-center">
                        <div class="col">
                            <h3 class="mb-1">Share key</h3>
                        </div>
                    </div>
                </div>
                Share key to list of users
                <h4>REQUEST</h4>
                <p>
                    <strong>Users</strong> - Array of users for key sharing<br>
                </p>
                <p>
                    <strong>Calendar</strong><br />
                    The calendar access to key for users. For Company Owner, Location Owner,
                    Company Manager, Location Manager, Installer, Key manager  null  Permanent, Temporary should be object. <br>
                    <strong>Start/End Date</strong> - The date of the start/end
                    of access to the key, in GMT format.<br />
                    <strong>DayNumber</strong> - The sequence number of the week
                    when the user has access to the key. 0 - Monday<br />
                    <strong>Start/End Time</strong> - display time access limits to the key. 
                    The time is counted in minutes. Start countdown at 00:00 a.m. 
                    For example if you want to give an access from 8:00 to 13:00 you have to pass 480 and 880
                </p>
                <p>
                    <strong>KeyIds</strong> - Array of shared keys Ids<br>
                </p>
                <p>
                    <strong>Message</strong> - Add a message for additional instructions for invited users.<br>
                </p>
                <p>
                   <strong>MayShare</strong> - Boolean which indicates sharing access to 3-rd users. 
                    It’s can only be used with Permanent access. So if you give a Permanent access 
                    with MayShare = true option this user will be able to share key to 3-rd users. 
                    But keep in mind that giving permission with MayShare is allowed for owners and managers only. 
                    Permanent users are not allowed to do sharing with MayShare.
                </p>
            </b-col>
            <b-col lg="6" md="12">
                <b-card bg-variant="light" title="Request:">
                    <b-card-text>
                        <pre v-highlightjs>
<code class="http rounded">POST /api/invite HTTP/1.1
Content-Type: application/json; charset=utf-8
Authorization: Bearer AccessToken

{
    "Users": [
        {
            "Name": "Pitt E.",
            "PhoneNumber": "+380965781202"
        },
        {
            "Name": "John D.",
            "PhoneNumber": "+380962351243"
        }
    ],
    "Calendar": {
        "AccessIntervals": [
            {
                "EndDate": "2021-07-10T13:55:00",
                "DailyAccesses": [
                    {
                        "Start": 1018,
                        "End": 1198,
                        "DayNumber": 3
                    }
                ],
                "StartDate": "2020-07-07T13:58:09"
            }
        ]
    },
    "KeyIds": [445, 443],
    "Message": "Welcome to Zesec 1!",
    "MayShare": false
}
</code></pre>
                    </b-card-text>
                </b-card>
                <b-card bg-variant="light" title="Response:">
                    <b-card-text>
                        <pre
                            v-highlightjs
                        ><code class=" rounded">{{shareResponse | prettyJson}}</code></pre>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>
        <b-row v-show="$route.params.page == 'revoke'">
            <b-col lg="6" md="12" class="pt-3">
                <div class="mb-3">
                    <div class="row align-items-center">
                        <div class="col">
                            <h3 class="mb-1">Revoke key from user</h3>
                        </div>
                    </div>
                </div>
                Revoke access to key from user. <br>
                <strong>keyAccessId - </strong> User key access Id. Generated when the user creates an invite.
            </b-col>
            <b-col lg="6" md="12">
                <b-card bg-variant="light" title="Request:">
                    <b-card-text>
                        <pre v-highlightjs>
<code class="http rounded">DELETE /api/key/access/{keyAccessId} HTTP/1.1
Content-Type: application/json; charset=utf-8
Authorization: Bearer AccessToken
</code></pre>
                    </b-card-text>
                </b-card>
                <b-card bg-variant="light" title="Response:">
                    <b-card-text>
                        <pre
                            v-highlightjs
                        ><code class=" rounded">{{deleteResponse | prettyJson}}</code></pre>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>

        <b-row v-show="$route.params.page == 'update-key'">
            <b-col lg="6" md="12" class="pt-3">
                <div class="mb-3">
                    <div class="row align-items-center">
                        <div class="col">
                            <h3 class="mb-1">Edit key accesses for current user.</h3>
                        </div>
                    </div>
                </div>
                <h4>REQUEST</h4>
                <p>
                    <strong>keyAccessId</strong> - User key access Id. Generated when the user creates an invite.
                    <strong>Start/End Date</strong> - The date of the start/end
                    of access to the key, in GMT format.<br />
                    <strong>DayNumber</strong> - The sequence number of the week
                    when the user has access to the key. 0 - Monday<br />
                    <strong>Start/End Time</strong> - display time access limits to the key. 
                    The time is counted in minutes. Start countdown at 00:00 a.m. 
                    For example if you want to give an access from 8:00 to 13:00 you have to pass 480 and 880
                </p>
               
            </b-col>
            <b-col lg="6" md="12">
                <b-card bg-variant="light" title="Request:">
                    <b-card-text>
                        <pre v-highlightjs>
<code class="http rounded">PUT /api/key/access/{keyAccessId} HTTP/1.1
Content-Type: application/json; charset=utf-8
Authorization: Bearer AccessToken

{
    "accessIntervals": [
        {
            "startDate": "2021-04-02T11:59:51.610Z",
            "endDate": "2021-04-21T11:59:51.610Z",
            "dailyAccesses": [
                {
                    "dayNumber": 0,
                    "start": 489,
                    "end":  660
                },
                {
                    "dayNumber": 4,
                    "start": 489,
                    "end":  660
                }
            ]
        }
    ]
}
</code></pre>
                    </b-card-text>
                </b-card>
                <b-card bg-variant="light" title="Response:">
                    <b-card-text>
                        <pre
                            v-highlightjs
                        ><code class=" rounded">{{updateResponse | prettyJson}}</code></pre>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { INIT_COMPLETED } from "@/store/types/global";

export default {
    data() {
        return {
            authResponse:
                '{"AccessToken":"eyJhbGniOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiIyIiwicGhvbmVOdW1iZXIiOiIrMzgwOTc2MDk2NDkzIiwibmJmIjoxNjEyOTU0MTcwLCJleHAiOjE2MTMxMjY5NzAsImlzcyI6Ilplc2VjSXNzdWVyIiwiYXVkIjoiWmVzZWNBdWRpZW5jZSJ9.WnaVvlVCYSvAqaFXuVrE7GSuFpLEjpPr-2roDtY_CqI","RefreshToken":"CbpCAvJXvQInLU5PDs0QmyhRKrNAcsM09bRgyK6n0XTIcmennRtMrlJ2Tqh5nRni9dQgCFoYLWvFrOVFWymRHM5XZwB3WzOLMtiDwdQNrCKHEEdrITrOfJ6B98VEhms","UserId":2}',
            keyUsersResponse:
                '[{"Id":7,"RoleId":7,"UserId":2,"CalendarId":348,"KeyId":"keyId","Role":{"LazyLoader":{},"Name":"Temporary","Type":7,"Id":7},"User":{"Id":2,"ImageId":212,"Email":"user1@mail.com","FirstName":"User","LastName":"One ","Name":"User One","PhoneNumber":"+46707000001","Type":"Full"}, "Calendar":{"AccessIntervals":[{"StartDate":"2020-12-28T00:00:00","EndDate":"2020-12-29T00:00:00","DailyAccesses":[{"DayNumber":0,"Start":0,"End":1439},{"DayNumber":6,"Start":0,"End":1439}]}],"CreatedAt":"2020-12-28T13:56:20","ExpiresAt":"2020-12-29T00:00:00"},"CreatedAt":"2020-12-28T13:56:20","DeletedAt":null,"Key":{"Id":"keyId","Name":"key 1","LocationId":1,"OwnerId":2,"CompanyId":2,"CreatedAt":"2020-09-01T10:49:26","DevicesCount":0,"UsersCount":2,"InvitesCount":4},"MayShare":false},{"Id":8,"RoleId":7,"UserId":3,"CalendarId":349,"KeyId":"keyId","Role":{"LazyLoader":{},"Name":"Temporary","Type":7,"Id":7},"User":{"Id":3,"ImageId":213,"Email":"user2@mail.com","FirstName":"User","LastName":"Two","Name":"User Two","PhoneNumber":"+46707000002","Type":"Full"}, "Calendar":{"AccessIntervals":[{"StartDate":"2020-12-28T00:00:00","EndDate":"2020-12-29T00:00:00","DailyAccesses":[{"DayNumber":0,"Start":0,"End":1439},{"DayNumber":6,"Start":0,"End":1439}]}],"CreatedAt":"2020-12-28T13:56:20","ExpiresAt":"2020-12-29T00:00:00"},"CreatedAt":"2020-12-28T13:56:20","DeletedAt":null,"Key":{"Id":"keyId","Name":"key2","LocationId":1,"OwnerId":2,"CompanyId":2,"CreatedAt":"2020-09-01T10:49:26","DevicesCount":0,"UsersCount":2,"InvitesCount":4},"MayShare":false}]',
            userKeysResponse:
                '[{"RoleId":7,"UserId":2,"CalendarId":348,"KeyId":"keyId","Role":{"LazyLoader":{},"Name":"Temporary","Type":7,"Id":7},"User":{"Id":2,"ImageId":212,"Email":"user1@mail.com","FirstName":"User","LastName":"One ","Name":"User One","PhoneNumber":"+46707000001","Type":"Full"}, "Calendar":{"AccessIntervals":[{"StartDate":"2020-12-28T00:00:00","EndDate":"2020-12-29T00:00:00","DailyAccesses":[{"DayNumber":0,"Start":0,"End":1439},{"DayNumber":6,"Start":0,"End":1439}]}],"CreatedAt":"2020-12-28T13:56:20","ExpiresAt":"2020-12-29T00:00:00"},"CreatedAt":"2020-12-28T13:56:20","DeletedAt":null,"Key":{"Id":"keyId","Name":"key 1","LocationId":1,"OwnerId":2,"CompanyId":2,"CreatedAt":"2020-09-01T10:49:26","DevicesCount":0,"UsersCount":2,"InvitesCount":4},"MayShare":false},{"RoleId":7,"UserId":3,"CalendarId":349,"KeyId":"keyId","Role":{"LazyLoader":{},"Name":"Temporary","Type":7,"Id":7},"User":{"Id":3,"ImageId":213,"Email":"user2@mail.com","FirstName":"User","LastName":"Two","Name":"User Two","PhoneNumber":"+46707000002","Type":"Full"}, "Calendar":{"AccessIntervals":[{"StartDate":"2020-12-28T00:00:00","EndDate":"2020-12-29T00:00:00","DailyAccesses":[{"DayNumber":0,"Start":0,"End":1439},{"DayNumber":6,"Start":0,"End":1439}]}],"CreatedAt":"2020-12-28T13:56:20","ExpiresAt":"2020-12-29T00:00:00"},"CreatedAt":"2020-12-28T13:56:20","DeletedAt":null,"Key":{"Id":"keyId","Name":"key2","LocationId":1,"OwnerId":2,"CompanyId":2,"CreatedAt":"2020-09-01T10:49:26","DevicesCount":0,"UsersCount":2,"InvitesCount":4},"MayShare":false}]',
            shareResponse:
                '[{"Id":293,"KeyId":450,"PhoneNumber":"+380965781202","Error":null},{"Id":294,"KeyId":450,"PhoneNumber":"+380962351243","Error":null}]',
            deleteResponse: '{"result": "Deleted"}',
            updateResponse: '{"result": "Updated"}',
        };
    },
    filters: {
        prettyJson(jsonString) {
            return JSON.stringify(JSON.parse(jsonString), null, 2);
        },
    },
    mounted() {
        this.$store.commit(INIT_COMPLETED);
    },
};
</script>

<style>
pre code {
    max-height: 400px;
}
</style>